import React, { useState, useRef, useEffect } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from "axios";
import MilestonesAfricaStores from '../../Assets/Images/Milestones/AfricaStores.jpg';
import Milestonescdnlogo_com_vodafone from '../../Assets/Images/Milestones/cdnlogo.com_vodafone.svg';
import MilestonesMind_Radio_logo_black_1 from '../../Assets/Images/Milestones/Mind-Radio-logo-black-1.png';
import Milestonesmind_stores from '../../Assets/Images/Milestones/mind-stores.png';
import Milestonesmind_tv from '../../Assets/Images/Milestones/mind-tv.jpg';
import Milestonesnew_logo from '../../Assets/Images/Milestones/new-logo.png';
import Milestonesspacio_logo_w from '../../Assets/Images/Milestones/spacio-logo-w.svg';
import Milestonessport_mate from '../../Assets/Images/Milestones/sport-mate.png';
import MindLogo from '../../Assets/Images/Milestones/mind-logo.webp'
import AllClasses from '../../Assets/Images/Milestones/AllClasses.png'

const MilestoneSection = () => {

    const Years = [
        { id: 0, image: MindLogo, year: 2010, yearInSlider: `10`, text: "All started here" },
        { id: 1, image: MindLogo, year: 2011, yearInSlider: `11`, text: "Establishing MIND for Media Innovations & Development" },
        { id: 2, image: '', year: 2012, yearInSlider: `12`, text: "TahrirFM /TahrirTV" },
        { id: 3, image: Milestonescdnlogo_com_vodafone, year: 2013, yearInSlider: `13`, text: "The voice of VIS (Vodafone)" },
        { id: 4, image: MilestonesMind_Radio_logo_black_1, year: 2014, yearInSlider: `14`, text: "MIND Radio" },
        { id: 5, image: MindLogo, year: 2015, yearInSlider: `15`, text: "Reconstructing the company to MIND for Information Technology and Media" },
        { id: 6, image: Milestonesmind_tv, year: 2016, yearInSlider: `16`, text: "Launching MIND TV" },
        { id: 7, image: Milestonesmind_stores, year: 2017, yearInSlider: `17`, text: "Launching MIND Stores (www.mind-stores.com)" },
        { id: 8, image: Milestonessport_mate, year: 2018, yearInSlider: `18`, text: "Launching Sports-mate" },
        { id: 9, image: AllClasses, year: 2019, yearInSlider: `19`, text: "Launching All Classes Application" },
        { id: 10, image: MilestonesAfricaStores, year: 2020, yearInSlider: `20`, text: "Launching Africa Stores (www.africa-stores.com)" },
        { id: 11, image: Milestonesspacio_logo_w, year: 2023, yearInSlider: `23`, text: "Launching Spacio World (www.Spacioworld.com)" },
        { id: 12, image: Milestonesnew_logo, year: 2024, yearInSlider: `24`, text: "Our new office in Nasr City" }
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0px',
        beforeChange: (current, next) => setSelectedYear(next)
    };

    const [selectedYear, setSelectedYear] = useState(1);
    const sliderRef = useRef(null);
    const textYearRef = useRef(null); // Ref for the TextYear div

    const handleYearClick = (index) => {
        setSelectedYear(index);
        sliderRef.current.slickGoTo(index);
    
        // Apply scrolling behavior only on mobile screens (less than 768px)
        if (window.innerWidth < 990) {
            if (textYearRef.current) {
                const textYearPosition = textYearRef.current.getBoundingClientRect().top + window.scrollY;
                const offset = 350; // The offset to stop before the TextYear div
                window.scrollTo({
                    top: textYearPosition - offset,
                    behavior: 'smooth'
                });
            }
        }
    };
    


    return (
        <section className="MileStoneAboutSection">
            <div className="container CustomContainer">
                <div className="row AboutPageRow">

                    <div className="col-lg-5 col-md-12 AboutPageCol">
                        <h1 className="AboutPageHeader">Milestone</h1>
                        <div className="UnderAboutHr"></div>
                        <span className="FirstAboutSpan">
                            MIND: A journey from media to tech. Established in 2010, we've expanded
                            from radio and TV to encompass digital solutions, retail, and tech
                            services
                        </span>
                    </div>

                    <div className="col-lg-1">
                        {Years.map((item, index) => (
                            <div
                                key={item.id}
                                className={`${item.id === selectedYear ? "SelectedYear" : "YearItemToSelect"}`}
                                onClick={() => handleYearClick(index)}
                            >
                                <span>{item.year}</span>
                            </div>
                        ))}
                    </div>

                    <div className="col-lg-6 AboutPageImage MileStoneCol">
                        <div className="slider-container">
                            <Slider ref={sliderRef} {...settings}>
                                {Years.map((item) => (
                                    <div key={item.id} className="slide">
                                        <div className="year-box">
                                            <div className="YearNumber">
                                                <h1>20</h1>
                                            </div>
                                            <div className="YearNumber">
                                                <h1>{item.yearInSlider}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>

                        <div className="TextYear" ref={textYearRef}>
                            <div className="MilestoneImage">
                                <img src={Years.find(i => i.id === selectedYear)?.image} width="200px" alt="" />
                            </div>
                            <h3>
                                {Years.find(i => i.id === selectedYear)?.text}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MilestoneSection;
