import React from "react";
import './Footer.css'
import Logo from '../../Assets/Images/Logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedinIn, faTiktok, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
const Footer = ()=>{
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return( 
        <section className="FooterSection">
                <div className="container">
                <footer>
                    <div className="container">
                    <div className="row FooterRow">
                            <div className="col-lg-3 FooterCol">
                                <div className="Footerlogo">
                                    <img src={Logo} width="130px" alt="" />
                                </div>
                                <span className="FooterLogoText">
                                    MIND provides complete solutions for your Marketing, Advertising and PR needs. We created MIND to add to your business’s success, internally and with.....<Link to='/about' className="ReadMoreFooter" onClick={ scrollToTop()} >Read more</Link>
                                </span>
                            </div>
                            <div className="col-lg-4 FooterCol">
                                <span className="FooterEventsTitle">News & Events</span>
                                <div className="FooterEventsItem">
                                    <span>
                                        Our latest Marketing & Social Media Solutions for Kiddy Corner
                                    </span>    
                                </div>
                                <div className="FooterEventsItem">
                                    <span>


                                        Coverage Of Kiddy Corner’s Graduation Party 
                                    </span>
                                </div>
                                <div className="FooterEventsItem">
                                    <span>
                                        Our Latest E-Commerce Solution
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-3 FooterCol">
                                <span className="FooterContactTitle">Contact info</span>
                                <div className="FooterContactItem">
                                    <b>Adress: </b><span>8 Mohammed Tawfik Diab, Nasr City, Cairo, Egypt.</span>
                                </div>
                                <div className="FooterContactItem">
                                    <b>Phone: </b><span>(+202) 22746241</span>
                                </div>
                                <div className="FooterContactItem">
                                    <b>Dubai WhatsApp: </b><span>(+971) 503365403</span>
                                </div>
                                <div className="FooterContactItem">
                                    <b>Email: </b><span>info@mindholding.net</span>
                                </div>

                             
                            </div>
                        </div>
                    </div>
                       
                </footer>
                    <div className="row PrivacyRow">
                        <div className="col-lg-9 PrivacyCol">©MIND for information technology & media. All Rights Reserved.</div>
                        <div className="col-lg-3 PrivacyCol">
                            <div className="FooterSocial">
                                <a className="FooterIcon" href="https://www.facebook.com/Mindholding">
                                <FontAwesomeIcon icon={faFacebookF} />
                                </a>
                                <a className="FooterIcon" href="https://www.linkedin.com/company/10383158?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A10383158%2Cidx%3A2-1-2%2CtarId%3A1452779817426%2Ctas%3Amind+for+infor">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                                </a>
                                <a className="FooterIcon" href="https://twitter.com/MindHolding">
                                <FontAwesomeIcon icon={faXTwitter} />
                                </a>
                                <a className="FooterIcon" href="https://www.instagram.com/mindholding">
                                <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a className="FooterIcon" href="https://www.tiktok.com/@mind.holding?is_from_webapp=1&sender_device=pc">
                                <FontAwesomeIcon icon={faTiktok} />
                                </a>
                                <a className="FooterIcon" href="https://www.youtube.com/user/MINDMediaInnovation">
                                <FontAwesomeIcon icon={faYoutube} />
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
        </section>
    );
}
export default Footer;