import React, { useState } from "react";
import './JobCard.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
const JobCard = ({ job }) => {
    const [showMore, setShowMore] = useState(false);
    const toggleShowMore = () => setShowMore(!showMore);

    return (
        <div className="col-lg-12 mb-4">
            <div className="card job-card shadow-sm">
                <div className="card-body">
                    <div className="card-image">
                        <div className="JobAvatar">
                            <FontAwesomeIcon icon={faUserTie}/>
                        </div>
                    <h5 className="card-title">{job.title}</h5>
                        
                    </div>
                    <p className="card-text">
                        {showMore ? job.About : `${job.About.substring(0, 150)}...`}
                        
                    </p>
                    <ul className="list-unstyled">
                        {job.RoleScope.slice(0, showMore ? job.RoleScope.length : 3).map((item, index) => (
                            <li key={index}>- {item}</li>
                        ))}
                    </ul>
                    {showMore && (
                        <div className="requirements">
                            <h6>Minimum Requirements:</h6>
                            <ul className="list-unstyled">
                                {job.MinimumRequirements.map((req, index) => (
                                    <li key={index}>- {req}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {showMore && (
                        <div className="requirements">
                            <h6>Required Skills:</h6>
                            <ul className="list-unstyled">
                                {job.RequiredSkills.map((req, index) => (
                                    <li key={index}>- {req}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {showMore&&
                        <span>If you think you are qualified for this job, please send your CV to <a href="mailto: info@mindholding.net">info@mindholding.net</a></span>
                    }
                    <br />
                    <span className="show-more" onClick={toggleShowMore}>
                        {showMore ? " Show Less" : " Show More"}
                    </span>

                </div>
            </div>
        </div>
    );
};
export default JobCard;